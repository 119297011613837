import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { User } from '@billytics/models';
import {
  ActionCodeSettings,
  Auth,
  AuthError,
  AuthErrorCodes,
  EmailAuthProvider,
  reauthenticateWithCredential,
  sendEmailVerification,
  sendPasswordResetEmail,
  updatePassword,
} from '@angular/fire/auth';
import { Router } from '@angular/router';
import { RedirectAction } from '../shared';

export const AUTH_SCOPE_CONTEXT_KEY = 'scope';

interface RegisterUserDevicePayload {
  userAgent: string;
  registeredDeviceId: string;
  firebaseMessagingToken: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private auth = inject(Auth);
  private router = inject(Router);

  constructor(private http: HttpClient) {}

  signUp(payload: { firstName: string; lastName: string }) {
    return this.http.post<void>(`${environment.apiUrl}/auth/sign-up`, payload);
  }

  getScopeContext() {
    return this.http.get<{ token: string }>(`${environment.apiUrl}/auth/scope-context`);
  }

  getAccountDetails(fetchUpdates: boolean) {
    return this.http.get<User>(`${environment.apiUrl}/account/own?fetchUpdates=${fetchUpdates}`);
  }

  async sendEmailVerificationLink() {
    if (!this.auth.currentUser) {
      await this.signOut();
      return;
    }
    const options: ActionCodeSettings = {
      url: `${environment.appUrl}/portal/dashboard/profile?redirectAction=${RedirectAction.FETCH_USER_UPDATES}`,
    };
    await sendEmailVerification(this.auth.currentUser, options);
  }

  async signOut(withRedirect: boolean = true) {
    await this.auth.signOut();
    window.localStorage.clear();
    if (withRedirect) {
      await this.router.navigate(['auth'], { replaceUrl: true });
    }
  }

  async sendForgotPasswordEmail(email: string) {
    const options: ActionCodeSettings = {
      url: `${environment.appUrl}/auth`,
    };
    await sendPasswordResetEmail(this.auth, email, options);
  }

  async updatePassword(currentPassword: string, newPassword: string) {
    const user = this.auth.currentUser;
    if (!user) {
      throw new Error('user must be logged in');
    }
    if (!user.email) {
      throw new Error('user must have a valid email');
    }
    const credential = EmailAuthProvider.credential(user.email, currentPassword);
    await reauthenticateWithCredential(user, credential);
    await updatePassword(user, newPassword);
  }

  getFirebaseErrorMessage(error: AuthError) {
    if (!error?.code) {
      return undefined;
    }
    switch (error.code) {
      case AuthErrorCodes.EMAIL_EXISTS:
        return 'Email already exist. Please try to login or reset password to continue.';
      case AuthErrorCodes.INVALID_LOGIN_CREDENTIALS:
        return 'Invalid email or password.';
      case AuthErrorCodes.TOO_MANY_ATTEMPTS_TRY_LATER:
        return `Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.`;
      default:
        return undefined;
    }
  }

  registerDevice(payload: RegisterUserDevicePayload) {
    return this.http.post<void>(`${environment.apiUrl}/account/register-device`, payload);
  }

  deleteAccount() {
    return this.http.delete<void>(`${environment.apiUrl}/account`);
  }
}
